import React from 'react'
import DataIcon from './DataIcon'
import StockIcon from './StockIcon'

const ContentComponent = (props) => {
  return (
    <section className="background__section-6 background__dashboard__section-6">
      {/* <p className="title">{props.i18n.messages['dashboard.text.trial.title']}</p> */}
      <p className="title">{props.i18n.messages['trial.text.section.1']} <strong>{props.i18n.messages['trial.text.section.2']}</strong> {props.i18n.messages['trial.text.section.3']}</p>
      <p className="subtitle">{props.i18n.messages['dashboard.text.trial.description']}</p>
      <ul>
        <li className="icon">
          <a href="/data">
            <p>{props.i18n.messages['nav.data']}</p>
            <DataIcon i18n={props.i18n} style={{ margin: 0 }} />
          </a>
        </li>
        <li className="icon">
          <a href="/stock">
            <p>{props.i18n.messages['nav.stock']}</p>
            <StockIcon i18n={props.i18n} style={{ margin: 0 }} />
          </a>
        </li>
      </ul>
    </section>
  )
}

export default ContentComponent;