import React, { useState, useEffect } from 'react'
import AOS from 'aos';
import PriceData from '../../common/PriceData'
import Support from '../../common/Support'
import DataIcon from '../../common/DataIcon'

const ContentComponent = (props) => {

  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className="background desktop background__data">
      <section className="background__section-1 background__data__section-1">
        <div className="bg-2 ptext">
          <div className="text1">
            <span>{props.i18n.messages['data.text.line1.title']}</span><br />
          </div>
          <div className="bg-1" />
          <section className="background__data__section-2">
            <ul>
              <li data-aos="zoom-out">
                <span className="number">1</span>
                <span className="content">
                  <div className="p2" />
                  <div className="text">
                    <p className="title">{props.i18n.messages['account']}</p>
                    <p className="subtitle">{props.i18n.messages['account.description1']}</p>
                    <p className="subtitle">{props.i18n.messages['account.description2']}</p>
                    <p className="subtitle">{props.i18n.messages['account.description3']}</p>
                  </div>
                </span>
              </li>
              <li data-aos="zoom-out">
                <span className="number">2</span>
                <span className="content">
                  <div className="p3" />
                  <div className="text">
                    <p className="title">{props.i18n.messages['gift']}</p>
                    <p className="subtitle">{props.i18n.messages['gift.description1']}</p>
                    <p className="subtitle">{props.i18n.messages['gift.description2']}</p>
                    <p className="subtitle">{props.i18n.messages['gift.description3']}</p>
                  </div>
                </span>
              </li>
              <li data-aos="zoom-out">
                <span className="number">3</span>
                <span className="content">
                  <div className="p4" />
                  <div className="text">
                    <p className="title">{props.i18n.messages['customer.gift.given']}</p>
                    <p className="subtitle">{props.i18n.messages['customer.gift.given.description1']}</p>
                    <p className="subtitle">{props.i18n.messages['customer.gift.given.description2']}</p>
                    <p className="subtitle">{props.i18n.messages['customer.gift.given.description3']}</p>
                    <p className="subtitle">{props.i18n.messages['customer.gift.given.description4']}</p>
                  </div>
                </span>
              </li>
            </ul>
            <div className="p-1" />
          </section>
        </div>
      </section>
      <PriceData i18n={props.i18n} style={{
        backgroundColor: "#f9f9f9"
      }} />
      <section className="background__data__section-3">
        <p className="note">
          {props.i18n.messages['price.note']}
        </p>
        <div className="link">
          <div className="text">
            {props.i18n.messages['data.text.line2.download1']}
          </div>
          <DataIcon />
          <div className="text">
            {props.i18n.messages['data.text.line2.download2']}
          </div>
        </div>
        <div className="store">
          <span className="apple-store" data-aos="zoom-in-up" onClick={() => {
            window.open("https://apps.apple.com/us/app/stoo-data/id1507396496", '_blank')
          }} />
          <span className="google-store" data-aos="zoom-in-up" onClick={() => {
            window.open("https://play.google.com/store/apps/details?id=com.mai.stoo_data", '_blank')
          }} />
        </div>
      </section>
      <Support 
      dispatchUpdateMessengerBox={props.dispatchUpdateMessengerBox}
      i18n={props.i18n} />
    </div>
  )
}

export default ContentComponent;