import React, { useState, useEffect } from 'react'
import { Drawer, Dropdown, Icon } from 'rsuite'
import classnames from 'classnames'

const languages = [
  {
    data: "vi",
    label: "🇻🇳",
    panel: true,
  },
  {
    data: "en",
    label: "🇬🇧",
    panel: false,
  },
]

const ContentComponent = (props) => {
  const [state, setState] = useState({
    active: 'dashboard',
    is_open_drawer: false,
    open_dropdown_language: false,
  })

  const toggle = () => {
    setState({
      ...state,
      is_open_drawer: !state.is_open_drawer
    })
  }

  useEffect(
    () => {
      // /stock
      let slicePath = window.location.pathname.split('/');
      let path = slicePath[1];

      setState({
        ...state,
        active: path || "dashboard"
      });
    }, [])

  const onSelect = (activeKey) => {
    setState({
      ...state,
      active: activeKey
    });
  }

  return (
    <section className="hamburger-icon">
      <header className="container">
        <span className="logo" onClick={toggle} />
        <Drawer
          placement={"right"}
          show={state.is_open_drawer}
          onHide={toggle}
          full
          style={{
            maxWidth: 320
          }}
        >
          <Drawer.Header>
          </Drawer.Header>
          <Drawer.Body>
            <ul className="hamburger-icon--menu">
              <li onClick={e => onSelect('dashboard')}>
                <a
                  className={classnames({
                    active: state.active === 'dashboard'
                  })}
                  href="/">{props.i18n.messages['nav.dashboard']}</a>
              </li>
              <li onClick={e => onSelect('data')}>
                <a
                  className={classnames({
                    active: state.active === 'data'
                  })}
                  href="/data">{props.i18n.messages['nav.data']}</a>
              </li>
              <li onClick={e => onSelect('stock')}>
                <a
                  className={classnames({
                    active: state.active === 'stock'
                  })}
                  href="/stock">{props.i18n.messages['nav.stock']}</a>
              </li>
              <li onClick={e => onSelect('')}>
                <a
                  className={classnames({
                    active: state.active === 'help'
                  })}
                  href="/help">{props.i18n.messages['nav.help']}</a>
              </li>
              <li onClick={e => onSelect('contact')}>
                <a
                  className={classnames({
                    active: state.active === 'contact'
                  })}
                  href="/contact">{props.i18n.messages['nav.contact']}</a>
              </li>
              {/* <li style={{
                marginLeft: -10,
              }}> */}
              <Dropdown
                title={props.i18n.messages['nav.login']}
                noCaret>
                <Dropdown.Item href="https://stock.stoo.vn" target='_blank'>
                  {props.i18n.messages['nav.stock']}
                </Dropdown.Item>
                <Dropdown.Item href="https://data.stoo.vn" target='_blank'>
                  {props.i18n.messages['nav.data']}
                </Dropdown.Item>
              </Dropdown>
              {/* </li> */}
              {/* <div className="language">
                <Dropdown
                  onToggle={e => {
                    setState({
                      ...state,
                      open_dropdown_language: !state.open_dropdown_language
                    })
                  }}
                  title={languages.filter(l => l.data === props.i18n.locale)[0].label}
                  open={state.open_dropdown_language}
                >
                  {languages.map(lang => <span key={lang.data}>
                    <Dropdown.Item
                      style={{ width: 50, fontSize: 25 }}
                      onClick={e => {
                        setState({
                          ...state,
                          open_dropdown_language: !state.open_dropdown_language
                        })
                        props.dispatchUpdateLanguage(lang.data)
                      }}>
                      {lang.label}
                    </Dropdown.Item>
                    {lang.panel ? <Dropdown.Item divider /> : null}
                  </span>
                  )}
                </Dropdown>
              </div> */}
            </ul>
          </Drawer.Body>
        </Drawer>
      </header>
    </section>
  )
}

export default ContentComponent;