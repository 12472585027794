import { useState, useEffect } from 'react'

const ContentComponent = (size) => {
    const [state, setState] = useState({
      screenWidth: window.innerWidth
    })
  
    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange.bind(this));
  
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange.bind(this));
      }
  
    }, [])
  
    const handleWindowSizeChange = e => {
      setState({
        ...state,
        screenWidth: window.innerWidth
      })
    }
  
    return state.screenWidth < size;
  }
  
  export default ContentComponent;