import React, { useState, useEffect } from 'react'
import DataDesktop from './DataDesktop'
import DataMobile from './DataMobile'
import useScreenSize from '../../../hooks/useScreenSize'

const ContentComponent = (props) => {
  let isMobile = useScreenSize(768);
  
  return isMobile ? (
    <DataMobile
      i18n={props.i18n}
    />
  ) : (
      <DataDesktop
        i18n={props.i18n}
      />
    )
}

export default ContentComponent;