import React from 'react'
import { ConnectedComponent as connect } from '../../constants'

const ContentComponent = (props) => {
    return (
        <section className="background__section-7">
            <ul>
                <li className="subtitle">
                    <span className="text">{props.i18n.messages['support.text.line1']}</span>
                </li>
                <li className="subtitle">
                    <span className="text">{props.i18n.messages['support.text.line2']}</span>
                </li>
                <li className="subtitle">
                    <span className="text">{props.i18n.messages['support.text.line3']}</span>
                </li>
            </ul>
            <div className="btn">
                <div className="as-btn noselect" data-aos="zoom-in"
                    onClick={() => {
                        props.dispatchUpdateMessengerBox(true);
                    }}>
                    {props.i18n.messages['support.text.btn']}
                </div>
            </div>
        </section>
    )
}

export default connect(ContentComponent);