import React, { useState, useEffect } from 'react'
import AOS from 'aos';
import Support from '../../common/Support'
import PriceStock from '../../common/PriceStock'

const ContentComponent = (props) => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className="background mobile">
      <section className="background__section-1">
        <div className="bg-2">
          <div className="text1">
            <span>{props.i18n.messages['dashboard.text.line1.1']}</span><br />
            <span>{props.i18n.messages['dashboard.text.line1.2']}</span><br />
            <span>{props.i18n.messages['dashboard.text.line1.3']}</span><br />
          </div>
          <div className="store">
            <span className="apple-store" data-aos="zoom-in-up" onClick={() => {
              window.open("https://apps.apple.com/us/app/stoo-stock/id1500872352", '_blank')
            }} />
            <span className="google-store" data-aos="zoom-in-up" onClick={() => {
              window.open("https://play.google.com/store/apps/details?id=com.mai.stoo", '_blank')
            }} />
          </div>
          <div className="bg-1">
            <div className="bg-4" data-aos="fade-left" data-aos-duration="1500" />
          </div>
        </div>
      </section>
      <section className="background__section-2">
        <div className="bg-3">
          <ul className="list-card">
            <li className="card">
              <div className="bg-6" />
              <p>{props.i18n.messages['product']}</p>
              <span>{props.i18n.messages['products.description']}</span>
            </li>
            <li className="card" data-aos="zoom-in">
              <div className="bg-7" />
              <p>{props.i18n.messages['event']}</p>
              <span>{props.i18n.messages['event.description']}</span>
            </li>
          </ul>
          <ul className="list-card mt-50px">
            <li className="card">
              <div className="bg-8" />
              <p>{props.i18n.messages['qr']}</p>
            </li>
            <li className="card" data-aos="zoom-in">
              <div className="bg-9" />
              <p>{props.i18n.messages['account']}</p>
              <span>{props.i18n.messages['account.description']}</span>
            </li>
          </ul>
        </div>
      </section>
      <section className="background__section-3">
        <div className="title">
          <p></p>
          <p className="text">{props.i18n.messages['dashboard.text.line2.title']}</p>
        </div>
        <ul>
          <li data-aos="fade-up" className="subtitle">
            <div className="list-1" />
            <span className="text">{props.i18n.messages['dashboard.text.line2.subtitle1']}</span>
          </li>
          <li data-aos="fade-up" className="subtitle">
            <div className="list-2" />
            <span className="text">{props.i18n.messages['dashboard.text.line2.subtitle2']}</span>
          </li>
          <li data-aos="fade-up" className="subtitle">
            <div className="list-3" />
            <span className="text">{props.i18n.messages['dashboard.text.line2.subtitle3']}</span>
          </li>
        </ul>
      </section>
      <PriceStock i18n={props.i18n} />
      <Support i18n={props.i18n} />
    </div>
  )
}

export default ContentComponent;