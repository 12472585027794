import * as TYPE from './types'

// CRUD
const dispatchTrialCreateAsync = (data) => ({
  type: TYPE.TRIAL_CREATE,
  data,
});
export default {
  dispatchTrialCreateAsync,
};

// action 
export const CreateAsync = (payload) => {
  return {
    type: TYPE.TRIAL_CREATE_ASYNC,
    payload
  }
}