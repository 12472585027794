import React, { useState, useEffect } from 'react'
import DashboardMobile from './DashboardMobile'
import DashboardDesktop from './DashboardDesktop'
import useScreenSize from '../../../hooks/useScreenSize'

const ContentComponent = (props) => {
  let isMobile = useScreenSize(768);
  
  return isMobile ? (
    <DashboardMobile
      i18n={props.i18n}
    />
  ) : (
      <DashboardDesktop
        i18n={props.i18n}
      />
    )
}

export default ContentComponent;