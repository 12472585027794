import React, { Component, lazy, Suspense } from 'react'
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom'
import UserLayout from './layout/UserLayout'
import {
  ConnectedComponent as connect,
} from './constants'

const Dashboard = lazy(() => import('./views/app/Dashboard'))
const Stock = lazy(() => import('./views/app/Stock'))
const Data = lazy(() => import('./views/app/Data'))
const Price = lazy(() => import('./views/app/Price'))
const Help = lazy(() => import('./views/app/Help'))
const Contact = lazy(() => import('./views/app/Contact'))
const NotFound = lazy(() => import('./views/notfound'));

const LoadingMessage = () => (
  <div className="loading" />
)

class MainApp extends Component {
  render() {
    let { accounts } = this.props;

    return (
      <Router>
        <Suspense fallback={<LoadingMessage />}>
            <Switch>
              <Route path="/" exact component={Dashboard} />
              <Route path="/stock" component={Stock} />
              <Route path="/data" component={Data} />
              <Route path="/help" component={Help} />
              <Route path="/contact" component={Contact} />
              <Route component={NotFound} />
            </Switch>
        </Suspense>
      </Router>
    )
  }
}

export default connect(MainApp);

// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52/
