import React, { useState, useEffect } from 'react'
import AOS from 'aos';
import HelpRegisterTrial from '../../common/HelpRegisterTrial'
import { Timeline } from "rsuite";

const ContentComponent = (props) => {

  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className="background mobile">
      <section className="background__section-8 background__help__section-8">
        <div className="p2-1"></div>
        <div className="p2-2"></div>
        <div className="title">
          <div className="text1">
            <h6>{props.i18n.messages['help.title']}</h6>
          </div>
          <div className="p2-3" />
        </div>
        <div className="timeline">
          <Timeline align="left">
            <Timeline.Item dot={<span className="timeline__dot">1</span>}>
              <div className="hdsd-1" />
              <p>{props.i18n.messages['help.sub.1']}</p>
            </Timeline.Item>
            <Timeline.Item dot={<span className="timeline__dot">2</span>}>
              <div className="hdsd-2" />
              <p>{props.i18n.messages['help.sub.2']}</p>
            </Timeline.Item>
            <Timeline.Item dot={<span className="timeline__dot">3</span>}>
              <div className="hdsd-3" />
              <p>{props.i18n.messages['help.sub.3']}</p>
            </Timeline.Item>
            <Timeline.Item dot={<span className="timeline__dot">4</span>}>
              <div className="hdsd-4" />
              <p>{props.i18n.messages['help.sub.4']}</p>
            </Timeline.Item>
            <Timeline.Item dot={<span className="timeline__dot">5</span>}>
              <div className="hdsd-5" />
              <p>{props.i18n.messages['help.sub.5']}</p>
            </Timeline.Item>
            <Timeline.Item dot={<span className="timeline__dot">6</span>}>
              <div className="hdsd-6" />
              <p>{props.i18n.messages['help.sub.6']}</p>
            </Timeline.Item>
          </Timeline>
        </div>
        <div className="trial">
          <HelpRegisterTrial
            type={"data|1"}
          />
        </div>
      </section>
    </div>
  )
}

export default ContentComponent;