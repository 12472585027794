import React from 'react'

const ContentComponent = (props) => {
    return (
        <div className="bg-logo-icon" style={props.style}>
            <div className="bg">
                <div className="data-logo" />
            </div>
        </div>
    )
}

export default ContentComponent;