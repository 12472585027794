import React, { useState, useEffect } from 'react';
import { ConnectedComponent as connect } from '../../constants'
import { NavLink, Link } from 'react-router-dom'
import Nav from './Nav'
import Hamburger from './Hamburger'
import ReactDOM from 'react-dom'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import { toast } from 'react-toastify'

const Toolbar = props => {
  const [state, setState] = useState({
    messenger_box: props.i18n.messenger_box || false
  })

  useEffect(() => {
    // console.log(props.i18n.messenger_box);

    setState({
      ...state,
      messenger_box: props.i18n.messenger_box,
    })
  }, [props.i18n.messenger_box])

  useEffect(() => {
    if (props.trial && props.trial.message) {
      if (props.trial.status === 200) {
        if (props.trial.message) {
          toast.info(props.trial.message)
        }
        else {
          toast.info(props.i18n.messages['success'])
        }
      }
      else {
        if (props.trial.message) {
          toast.error(props.trial.message)
        }
        else {
          toast.error(props.i18n.messages['error'])
        }
      }
    }
  }, [props.trial])


  return (
    <nav className="toolbar__navigation">
      <Link className="toolbar__navigation--logo" to="/" />
      <div className="spacer" />
      <Hamburger
        i18n={props.i18n}
        dispatchUpdateLanguage={props.dispatchUpdateLanguage}
      />
      <Nav
        i18n={props.i18n}
        dispatchUpdateLanguage={props.dispatchUpdateLanguage}
      />
      <MessengerCustomerChat
        pageId="111806810403778"
        appId="794558471071220"
        shouldShowDialog={state.messenger_box}
        minimized={true}
        onCustomerChatDialogHide={() => {
          props.dispatchUpdateMessengerBox(false)
        }}
      />
    </nav>
  )
}

export default connect(Toolbar)
