import React, { useState, useEffect } from 'react'
import AOS from 'aos';
import { ConnectedComponent as connect } from '../../../constants'
import { Icon } from 'rsuite';

const ContentComponent = (props) => {

  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className="background mobile background__contact">
      <section className="background__section-1 background__contact__section-1">
        <div className="bg-2">
          <div className="text1">
            <span>{props.i18n.messages['contact.text.line1.title']}</span><br />
          </div>
          <ul className="card">
            <li>
              <Icon icon='map-o' /><p> {props.i18n.messages['contact.text.card.1']}</p>
            </li>
            <li>
              <Icon icon='map-marker' /> <p> {props.i18n.messages['contact.text.card.2']}</p>
            </li>
            <li>
              <Icon icon='phone' /> <p> {props.i18n.messages['contact.text.card.3']}</p>
            </li>
            <li>
              <Icon icon='comment' /><p> {props.i18n.messages['contact.text.card.4']}</p>
            </li>
            <li>
              <Icon icon='clock-o' /> <p> {props.i18n.messages['contact.text.card.5']}</p>
            </li>
          </ul>
          <div className="p-5" />
        </div>
      </section>
    </div>
  )
}
export default connect(ContentComponent);