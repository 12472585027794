import React, { useState, useEffect } from 'react'
import {
  Button, ButtonToolbar,
  Form, FormControl, FormGroup, ControlLabel,
  Grid, Row, Col,
} from 'rsuite'
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal"
import {
  MapPriceName,
  MapPriceLevel,
} from '../../helpers/Trial'
import { Google } from '../../constants'
import ReCAPTCHA from "react-google-recaptcha"
import { toast } from 'react-toastify'

const ContentComponent = (props) => {
  const {
    open,
    toggle,
    type,
    i18n,
  } = props;

  const [state, setState] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    content: "",
    price_name: MapPriceName.get(type.split('|')[0]),
    price_level: MapPriceLevel.get(type.split('|')[1]),
    captcha: null,
  })
  const myRef = React.useRef()

  const submit = () => {

    if (!state.phone || !state.phone) {
      return toast.error(i18n.messages['required.name.or.phone']);
    }

    // if (!state.captcha) {
    //   return toast.error('Yêu cầu xác thực mã captcha');
    // }

    // dispatch
    props.submit({
      name: state.name,
      email: state.email,
      phone: state.phone,
      address: state.address,
      content: state.content,
      price: `${state.price_name} - ${state.price_level}`,
      captcha: state.captcha,
    });

    return toast.info(i18n.messages['sending']);
  }

  const onChange = (val, e) => {
    const { target: { name, value } } = e;

    if (name) {
      setState({
        ...state,
        [name]: value,
      })
    }
  }

  const onChangeCaptcha = (val) => {
    setState({
      ...state,
      captcha: val,
    })
  }

  return (
    // <div ref={myRef}>
    <Modal
      open={open}
      onClose={toggle}
      center={false}
      container={myRef.current}
    >
      <div className="modal-require-trial">
        <p className="modal-require-trial__head">{i18n.messages['trial']}</p>
        <div className="modal-require-trial__body">
          <Grid fluid>
            <Row gutter={30}>
              <Col lg={12} md={12} xs={24} sm={24}>
                <Form fluid className="form">
                  <FormGroup>
                    <ControlLabel>{i18n.messages['trial.name']}</ControlLabel>
                    <FormControl name="name" value={state.name} onChange={onChange} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>{i18n.messages['trial.email']}</ControlLabel>
                    <FormControl name="email" type="email" value={state.email} onChange={onChange} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>{i18n.messages['trial.phone']}</ControlLabel>
                    <FormControl name="phone" type="text" value={state.phone} onChange={onChange} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>{i18n.messages['trial.address']}</ControlLabel>
                    <FormControl name="address" type="text" value={state.address} onChange={onChange} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>{i18n.messages['trial.price']}</ControlLabel>
                    <FormControl className="disable" type="text" value={`${state.price_name} - ${state.price_level}`} disabled />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>{i18n.messages['trial.content']}</ControlLabel>
                    <FormControl rows={5} name="content" componentClass="textarea" value={state.content} onChange={onChange} />
                  </FormGroup>
                  <FormGroup>
                    <ButtonToolbar className="group-btn">
                      {/* <ReCAPTCHA
                          sitekey={Google.CAPTCHA_V2}
                          onChange={onChangeCaptcha}
                        /> */}
                      <Button appearance="primary" onClick={submit}>{i18n.messages['trial.submit']}</Button>
                    </ButtonToolbar>
                  </FormGroup>
                </Form>
              </Col>
              <Col lg={12} md={12} xs={24} sm={24}>
                <div className="contact">
                  <div className="wrapper">
                    <h4><strong><span>{i18n.messages['trial.text.contact.1.1']}</span> {i18n.messages['trial.text.contact.1.2']}</strong></h4>
                    <p>
                      <strong className="nobottompadding">{i18n.messages['trial.text.contact.2']}</strong>
                    </p>
                    <h2 className="notoppadding">
                      <span style={{ color: '#F29e16' }}>
                        <strong className="text">0965-858-729</strong>
                      </span>
                    </h2>
                  </div>
                  <hr />
                  <div className="wrapper">
                    <p>
                      <strong className="nobottompadding">{i18n.messages['trial.text.contact.3']}</strong>
                    </p>
                    <h2 className="notoppadding">
                      <span style={{ color: '#F29e16' }}>
                        <strong className="text">info.stoovn@gmail.com</strong>
                      </span>
                    </h2>
                  </div>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    </Modal >
    // </div>
  )
}

export default ContentComponent;