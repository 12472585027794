import React from "react"
import { ConnectedComponent as connect } from '../../constants'


const Footer = (props) => {
  return (
    <div className="footer">
      <section className="footer__section-1">
        <ul>
          <li className="title">
            <p> {props.i18n.messages['footer.title.1']}</p>
          </li>
          <li>
            <a href="/contact">{props.i18n.messages['nav.contact.footer']}</a>
          </li>
          <li>
            <a href="/stock">{props.i18n.messages['nav.stock']}</a>
          </li>
          <li>
            <a href="/data">{props.i18n.messages['nav.data']}</a>
          </li>
          <li>
            <a href="/help">{props.i18n.messages['nav.help']}</a>
          </li>
        </ul>
      </section>
      <section className="footer__section-2">
        <ul>
          <li>
            <p>{props.i18n.messages['footer.title.2']}</p>
          </li>
          <li>
            <p className="title">{props.i18n.messages['footer.title.3']}</p>
          </li>
          <li>
            <p>{props.i18n.messages['footer.text.1']}</p>
          </li>
          <li>
            <p>{props.i18n.messages['footer.text.2']}</p>
          </li>
          <li>
            <p>{props.i18n.messages['footer.text.3']}</p>
          </li>
          <li>
            <p>{props.i18n.messages['footer.text.4']}</p>
          </li>
          <li>
            <p>{props.i18n.messages['footer.text.5']}</p>
          </li>
          <li className="copyright">
            <p>{props.i18n.messages['footer.text.6']}</p>
          </li>
        </ul>
      </section>
    </div>
  )
}

export default connect(Footer)
