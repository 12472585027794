import React, { useState, useEffect } from 'react'
import PriceCard from './PriceCard'

const ContentComponent = (props) => {
  return (
    <section className="background__price">
      <section className="background__price__section-2">
        <p className="head">{props.i18n.messages['price.text.title']}</p>
        <ul>
          <li>
            <PriceCard
              type={'stock|1'}
              style={props.style}
              title={props.i18n.messages['price.card.title.1']}
              price={"10.000.000"}
              time={props.i18n.messages['year']}
              descriptions={[
                props.i18n.messages['price.stock.card.1.description1'],
                props.i18n.messages['price.stock.card.1.description2'],
                props.i18n.messages['price.stock.card.description3'],
                props.i18n.messages['price.stock.card.description4'],
                props.i18n.messages['price.stock.card.description5'],
                props.i18n.messages['price.stock.card.description6'],
                props.i18n.messages['price.stock.card.description7'],
                props.i18n.messages['price.stock.card.description8'],
                props.i18n.messages['price.stock.card.description9'],
              ]}
              i18n={props.i18n} />
          </li>
          <li>
            <PriceCard
              type={'stock|2'}
              style={props.style}
              title={props.i18n.messages['price.card.title.2']}
              price={"15.000.000"}
              time={props.i18n.messages['year']}
              descriptions={[
                props.i18n.messages['price.stock.card.2.description1'],
                props.i18n.messages['price.stock.card.2.description2'],
                props.i18n.messages['price.stock.card.description3'],
                props.i18n.messages['price.stock.card.description4'],
                props.i18n.messages['price.stock.card.description5'],
                props.i18n.messages['price.stock.card.description6'],
                props.i18n.messages['price.stock.card.description7'],
                props.i18n.messages['price.stock.card.description8'],
                props.i18n.messages['price.stock.card.description9'],
              ]}
              i18n={props.i18n} />
          </li>
          <li>
            <PriceCard
              type={'stock|3'}
              style={props.style}
              title={props.i18n.messages['price.card.title.3']}
              price={"25.000.000"}
              time={props.i18n.messages['year']}
              descriptions={[
                props.i18n.messages['price.stock.card.3.description1'],
                props.i18n.messages['price.stock.card.3.description2'],
                props.i18n.messages['price.stock.card.description3'],
                props.i18n.messages['price.stock.card.description4'],
                props.i18n.messages['price.stock.card.description5'],
                props.i18n.messages['price.stock.card.description6'],
                props.i18n.messages['price.stock.card.description7'],
                props.i18n.messages['price.stock.card.description8'],
                props.i18n.messages['price.stock.card.description9'],
              ]}
              i18n={props.i18n} />
          </li>
        </ul>
      </section>
    </section>
  )
}
export default ContentComponent;