import React from 'react'
import ContactDesktop from './ContactDesktop'
import ContactMobile from './ContactMobile'
import useScreenSize from '../../../hooks/useScreenSize'

const ContentComponent = (props) => {
  let isMobile = useScreenSize(768);

  return isMobile ? (
    <ContactMobile
      i18n={props.i18n}
    />
  ) : (
      <ContactDesktop
        i18n={props.i18n}
      />
    )
}

export default ContentComponent;