import React, { useState, useEffect } from 'react'
import { ConnectedComponent as connect } from '../../constants'
import ModalRequireTrial from './ModalRequireTrial'
import { toast } from 'react-toastify'

const ContentComponent = (props) => {
  const {
    type,
    title,
    price,
    time,
    descriptions = [],
    style,
  } = props;

  const [state, setState] = useState({
    open: false,
  })
  const toggle = () => setState({ ...state, open: !state.open })

  const onSubmit = (values) => {
    props.dispatchTrialCreateAsync(values);

    toggle();
  }

  return (
    <div className="card" style={props.style}>
      <ModalRequireTrial
        submit={onSubmit}
        open={state.open}
        toggle={toggle}
        type={type}
        i18n={props.i18n}
      />

      <div className="title">{title}</div>
      <div className="price">
        <span className="number">{`${price}`}<sup>đ</sup></span>/<sub>{time}</sub>
      </div>
      <div className="btn">
        <div className="as-btn noselect"
          onClick={toggle}>
          {props.i18n.messages['dashboard.text.trial.btn']}
        </div>
      </div>
      <div className="description">
        {descriptions.map((description, idx) => <div key={idx} >{description}</div>)}
      </div>
    </div>
  )
}
export default connect(ContentComponent);