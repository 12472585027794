import React, { useState, useEffect } from 'react'
import AOS from 'aos';
import Trial from '../../common/Trial'

const ContentComponent = (props) => {

  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className="background mobile background__dashboard">
      <section className="background__section-1 background__dashboard__section-1">
        <div className="bg-2">
        </div>
        <div className="bg-1" />

        <section className="background__section-5">
          <div className="text">
            <p className="title">{props.i18n.messages['dashboard.text.whyuse.title']}</p>
            <p
              className="subtitle"
              data-aos-duration="1000"
              data-aos="zoom-in">
              {props.i18n.messages['dashboard.text.whyuse.subtitle1']}
            </p>
            <p
              className="subtitle"
              data-aos-duration="1000"
              data-aos="zoom-in">
              {props.i18n.messages['dashboard.text.whyuse.subtitle2']}
            </p>
            <p
              className="subtitle"
              data-aos-duration="1000"
              data-aos="zoom-in">
              {props.i18n.messages['dashboard.text.whyuse.subtitle3']}
            </p>
            <p
              className="subtitle"
              data-aos-duration="1000"
              data-aos="zoom-in">
              {props.i18n.messages['dashboard.text.whyuse.subtitle4']}
            </p>
            <p
              className="subtitle"
              data-aos-duration="1000"
              data-aos="zoom-in">
              {props.i18n.messages['dashboard.text.whyuse.subtitle5']}
            </p>
            <p
              className="subtitle"
              data-aos-duration="1000"
              data-aos="zoom-in">
              {props.i18n.messages['dashboard.text.whyuse.subtitle6']}
            </p>
          </div>
          <div className="bg-5"></div>
        </section>
        <Trial i18n={props.i18n} />
      </section>
    </div>
  )
}

export default ContentComponent;