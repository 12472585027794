import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Url } from '../../constants'

/*********************
 *                   *
 *  axios to api     *
 *                   *
 *********************/
function asyncCreate(data) {
  const { data: {
    name,
    email,
    phone,
    address,
    content,
    price,
    captcha,
  } } = data;

  return axios.post(Url.END_POINT + "/api/trial",
    {
      name,
      email,
      phone,
      address,
      content,
      price,
      captcha,
    },
    {
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then(res => res)
    .catch(err => err.response)
}

/*********************
 *                   *
 *  TO ACTION        *
 *                   *
 *********************/
function* actionCreate(data) {
  const response = yield call(asyncCreate, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { status, Message } = response.data;
      result.message = Message;
      result.status = status;
    }
  }
  yield put(actions.CreateAsync(result))
}

/*********************
 *                   *
 *  CUSTOM run SAGA  *
 *                   *
 *********************/
import { takeLatest, takeEvery } from 'redux-saga/effects'
import "@babel/polyfill"
import * as TYPE from './types'

function* CustomSaga() {
  yield takeLatest(TYPE.TRIAL_CREATE, actionCreate)
}

export default CustomSaga