import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import {
  Dropdown
} from 'rsuite'

const languages = [
  {
    data: "vi",
    label: "🇻🇳",
    panel: true,
  },
  {
    data: "en",
    label: "🇬🇧",
    panel: false,
  },
]

const ContentComponent = (props) => {
  const [state, setState] = useState({
    active: 'dashboard',
    open_dropdown_language: false,
  })

  useEffect(
    () => {
      // /stock
      let slicePath = window.location.pathname.split('/');
      let path = slicePath[1];

      setState({
        ...state,
        active: path || "dashboard"
      });
    }, [])

  const onSelect = (activeKey) => {
    setState({
      ...state,
      active: activeKey
    });
  }

  return (
    <ul className="toolbar__navigation--menu">
      <li onClick={e => onSelect('dashboard')}>
        <a
          className={classnames({
            active: state.active === 'dashboard'
          })}
          href="/">{props.i18n.messages['nav.dashboard']}</a>
      </li>
      <li onClick={e => onSelect('data')}>
        <a
          className={classnames({
            active: state.active === 'data'
          })}
          href="/data">{props.i18n.messages['nav.data']}</a>
      </li>
      <li onClick={e => onSelect('stock')}>
        <a
          className={classnames({
            active: state.active === 'stock'
          })}
          href="/stock">{props.i18n.messages['nav.stock']}</a>
      </li>
      <li onClick={e => onSelect('help')}>
        <a
          className={classnames({
            active: state.active === 'help'
          })}
          href="/help">{props.i18n.messages['nav.help']}</a>
      </li>
      <li onClick={e => onSelect('contact')}>
        <a
          className={classnames({
            active: state.active === 'contact'
          })}
          href="/contact">{props.i18n.messages['nav.contact']}</a>
      </li>
      <Dropdown
        toggleComponentClass="li"
        className="as-btn"
        title={props.i18n.messages['nav.login']}
        placement="bottomEnd"
        trigger={['click', 'hover']}
        noCaret>
        <Dropdown.Item href="https://stock.stoo.vn" target='_blank'>
          {props.i18n.messages['nav.stock']}
        </Dropdown.Item>
        <Dropdown.Item href="https://data.stoo.vn" target='_blank'>
          {props.i18n.messages['nav.data']}
        </Dropdown.Item>
      </Dropdown>
      {/* <div>
        <Dropdown
          onToggle={e => {
            setState({
              ...state,
              open_dropdown_language: !state.open_dropdown_language
            })
          }}
          title={languages.filter(l => l.data === props.i18n.locale)[0].label}
          open={state.open_dropdown_language}
        >
          {languages.map(lang => <span key={lang.data}>
            <Dropdown.Item
              style={{ width: 50, fontSize: 25 }}
              onClick={e => {
                setState({
                  ...state,
                  open_dropdown_language: !state.open_dropdown_language
                })
                props.dispatchUpdateLanguage(lang.data)
              }}>
              {lang.label}
            </Dropdown.Item>
            {lang.panel ? <Dropdown.Item divider /> : null}
          </span>
          )}
        </Dropdown>
      </div> */}
    </ul>
  )
}

export default ContentComponent