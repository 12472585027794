import React, { useState, useEffect } from 'react'
import HelpDesktop from './HelpDesktop'
import HelpMobile from './HelpMobile'
import useScreenSize from '../../../hooks/useScreenSize'

const ContentComponent = (props) => {
  let isMobile = useScreenSize(768);
  
  return isMobile ? (
    <HelpMobile
      i18n={props.i18n}
    />
  ) : (
      <HelpDesktop
        i18n={props.i18n}
      />
    )
}

export default ContentComponent;