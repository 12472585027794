import React, { useState, useEffect } from 'react'
import StockDesktop from './StockDesktop'
import StockMobile from './StockMobile'
import useScreenSize from '../../../hooks/useScreenSize'

const ContentComponent = (props) => {
  let isMobile = useScreenSize(768);
  
  return isMobile ? (
    <StockMobile
      i18n={props.i18n}
    />
  ) : (
      <StockDesktop
        i18n={props.i18n}
      />
    )
}

export default ContentComponent;